



























































import { DRangeSlider } from '@/components/Base'
import { mapGetters } from 'vuex'
import { convertUnitIfImperial, convertValIfImperial } from '@/store/utils/index'
import Vue from 'vue'
import { MAX_RANGE } from '@/store/modules/radar_mask_zones/types'
import { FormMixin } from '@/components/Mixins'

const props = {
  disabled: {
    type: Boolean,
    default: false
  },
  mask: {
    type: Object
  }
}

export default Vue.extend({
  name: 'RadarZMaskForm',
  components: { DRangeSlider },
  data: () => ({
    maxRange: MAX_RANGE
  }),
  mixins: [FormMixin],
  props,
  mounted() {
    this.$refs.radarZMaskForm.validate()
  },
  computed: {
    ...mapGetters('system', ['systemSetting']),
    range: {
      get(): number[] {
        return [
          this.convertValIfImperial(
            this.systemSetting,
            this.mask.range[0],
            'm'
          ),
          this.convertValIfImperial(this.systemSetting, this.mask.range[1], 'm')
        ]
      },
      set(val: number[]) {
        this.mask.range = [
          this.convertValIfImperial(this.systemSetting, val[0], 'ft'),
          this.convertValIfImperial(this.systemSetting, val[1], 'ft')
        ]
      }
    }
  },
  methods: {
    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,
    onDismissed(): void {
      this.$store.dispatch('sensors/CLEAR_STATUS')
    }
  }
})
