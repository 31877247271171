var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('event-detail-dialog',{attrs:{"event":_vm.event,"value":_vm.dialog},on:{"onClickClose":_vm.onClickClose}}),_c('v-card-title',[_c('div',{staticClass:"mb-1"},[_c('strong',[_vm._v("Detection Event Details")])])]),_c('v-card-text',[_c('v-layout',{staticClass:"ml-2 search-fields",attrs:{"row":""}},[_c('v-text-field',{staticClass:"mr-4",attrs:{"label":"Search Group ID","id":"targetIdField"},on:{"blur":_vm.getTableData},model:{value:(_vm.targetIdSearch),callback:function ($$v) {_vm.targetIdSearch=$$v},expression:"targetIdSearch"}}),_c('v-select',{staticClass:"mr-4",attrs:{"label":("Confirmed By" + (_vm.selectedConfirmedBy.length === 0 ? ': All' : '')),"items":_vm.allConfirmedBy,"multiple":""},on:{"blur":_vm.getTableData},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedConfirmedBy.length === 0 ? 'All' : _vm.selectedConfirmedBy.join(', '))+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedConfirmedBy),callback:function ($$v) {_vm.selectedConfirmedBy=$$v},expression:"selectedConfirmedBy"}}),_c('v-select',{staticClass:"mr-4",attrs:{"label":("Detections" + (_vm.selectedDetectionTypes.length === 0 ? ': All' : '')),"items":_vm.allDetectionTypes,"multiple":""},on:{"blur":_vm.getTableData},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedDetectionTypes.length === 0 ? 'All' : _vm.selectedDetectionTypes.join(', '))+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedDetectionTypes),callback:function ($$v) {_vm.selectedDetectionTypes=$$v},expression:"selectedDetectionTypes"}}),_c('v-select',{staticClass:"mr-4",attrs:{"label":("Vendors" + (_vm.selectedVendors.length === 0 ? ': All' : '')),"items":_vm.vendors,"multiple":""},on:{"blur":_vm.getTableData},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedVendors.length === 0 ? 'All' : _vm.selectedVendors.join(', '))+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedVendors),callback:function ($$v) {_vm.selectedVendors=$$v},expression:"selectedVendors"}}),_c('v-select',{staticClass:"mr-4",attrs:{"label":("Protocols" + (_vm.selectedProtocols.length === 0 ? ': All' : '')),"items":_vm.protocols,"multiple":""},on:{"blur":_vm.getTableData},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedProtocols.length === 0 ? 'All' : _vm.selectedProtocols.join(', '))+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedProtocols),callback:function ($$v) {_vm.selectedProtocols=$$v},expression:"selectedProtocols"}}),_c('v-select',{staticClass:"mr-4",attrs:{"label":("Detection State" + (_vm.selectedDetectionStates.length === 0 ? ': All' : '')),"items":_vm.allDetectionStates,"multiple":""},on:{"blur":_vm.getTableData},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedDetectionStates.length === 0 ? 'All' : _vm.selectedDetectionStates.join(', '))+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedDetectionStates),callback:function ($$v) {_vm.selectedDetectionStates=$$v},expression:"selectedDetectionStates"}}),_c('v-select',{staticClass:"mr-4",attrs:{"label":("Probability" + (_vm.selectedProbabilities.length === 0 ? ': All' : '')),"items":_vm.allProbabilities,"item-text":"label","multiple":""},on:{"blur":_vm.getTableData},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedProbabilities.length === 0 ? 'All' : _vm.allProbabilities.filter(function (p) { return _vm.selectedProbabilities.includes(p.value); }).map(function (p) { return p.label; }).join(', '))+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedProbabilities),callback:function ($$v) {_vm.selectedProbabilities=$$v},expression:"selectedProbabilities"}})],1),_c('v-progress-linear',{class:((_vm.fetchingData ? '' : 'hidden') + " mt-0 pt-0"),attrs:{"height":"1","indeterminate":"","color":"primary"}}),_c('v-data-table',{attrs:{"total-items":_vm.pagination.totalItems,"headers":_vm.headers,"items":_vm.events,"pagination":_vm.pagination,"rows-per-page-items":_vm.pageSizeItems,"mustSort":true},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_vm._v(_vm._s(_vm.shortId(props.item.target_id)))]),_c('td',[_vm._v(_vm._s(_vm.getSiteTime(props.item.start_replay_time || props.item.start_time)))]),_c('td',[_vm._v(_vm._s(props.item.duration.toFixed(2)))]),_c('td',[_vm._v(_vm._s(props.item.vendor))]),_c('td',[_vm._v(_vm._s(props.item.protocol))]),_c('td',[_vm._v(_vm._s(props.item.num_detections))]),_c('td',[_vm._v(_vm._s(props.item.classification))]),_c('td',[_vm._v(_vm._s(_vm.parseProbability(props.item.probability)))]),_c('td',[_vm._v(_vm._s(_vm.parseConfirmedBy(props.item)))]),_c('td',{staticClass:"text-capitalize"},[_vm._v(_vm._s(props.item.state))]),_c('td',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"pl-4",attrs:{"hide-details":"","color":"primary"},on:{"change":function($event){return _vm.falsePositiveClicked(props.item.id, $event)}},model:{value:(props.item.false_positive),callback:function ($$v) {_vm.$set(props.item, "false_positive", $$v)},expression:"props.item.false_positive"}},on))]}}],null,true)},[_c('span',[_vm._v("Mark as false positive")])])],1),_c('td',[_c('v-btn',{attrs:{"data-testid":("magnifying-" + (props.item.id)),"disabled":_vm.outDatedCheck(props.item.end_time),"small":"","icon":"","loading":_vm.loadingTrack && _vm.event.id === props.item.id},on:{"click":function($event){return _vm.onClickEvent(props.item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("search")])],1)],1),(_vm.janus)?_c('td',{staticStyle:{"white-space":"nowrap"}},[(
              !props.item.video_recordings.length ||
                _vm.outDatedCheck(props.item.end_time)
            )?_c('v-btn',{staticClass:"mx-0",attrs:{"small":"","icon":"","disabled":""}},[_c('v-icon',[_vm._v("play_circle_outline")])],1):_vm._l((props.item.video_recordings),function(recordingId){return _c('v-btn',{key:("play-" + recordingId),staticClass:"mx-0",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('playRecording', recordingId)}}},[_c('v-icon',{key:("icon-play-" + recordingId)},[_vm._v("play_circle_outline")])],1)})],2):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }