


































import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment-timezone'
import mixins from '../mixins'
import Vue from 'vue'

const props = {
  timezone: {
    type: String,
    default: moment.tz.guess()
  },
  totalCount: {
    type: Number,
    default: 0
  }
}

export default Vue.extend({
  name: 'DisruptionDetails',
  mixins: [mixins],
  props,
  data: (): any => ({
    debounceTimer: null,
    pageSizeItems: [5, 10, 25],
    reportType: 'cannon_disruption',
    fetchingData: false,
    events: [],
    pagination: {
      totalItems: 0,
      page: 1,
      rowsPerPage: 10,
      sortBy: 'id',
      descending: true
    },
    headers: [
      {
        text: 'ID',
        value: 'id',
        align: 'left',
        sortable: true
      },
      {
        text: 'Activation',
        value: 'activated_manually',
        sortable: true
      },
      {
        text: 'Deactivation',
        value: 'deactivated_manually',
        sortable: true
      },
      {
        text: 'User',
        value: 'activating_user_id',
        sortable: true
      },
      {
        text: 'Effector',
        value: 'cannon',
        sortable: false
      },
      {
        text: 'Serial Number',
        value: 'serial',
        sortable: false
      },
      {
        text: 'Date',
        value: 'date',
        sortable: false
      },
      {
        text: 'Started At',
        value: 'activated_at',
        sortable: true
      },
      {
        text: 'Stopped At',
        value: 'deactivated_at',
        sortable: true
      },
      {
        text: 'Duration (s)',
        value: 'duration',
        sortable: false
      }
    ],
    dsx_jammersSet: []
  }),
  async mounted() {
    await this.$store.dispatch('users/FETCH_USERS')
    this.$emitter.on('downloadReport', this.downloadReport)
    this.loadDsxJammers()
  },
  beforeDestroy() {
    this.$emitter.off('downloadReport', this.downloadReport)
  },
  computed: {
    ...mapGetters('cannons', ['cannonsSet']),
    ...mapGetters('rf_sensors', ['dsxSensorById']),
    ...mapGetters('reports', ['reportLink', 'range']),
    ...mapGetters('sites', ['activeSiteId']),
    ...mapState('users', ['usersList']),
    parsedReport() {
      return this.events.map(r => {
        const cannon = {} as any
        cannon.name = 'undefined'
        cannon.serial_number = 'undefined'

        if (Object.keys(this.cannonsSet).length > 0) {
          cannon.name = this.cannonsSet[r.cannon_id].name
          cannon.serial_number = this.cannonsSet[r.cannon_id].serial_number
        } else if (this.dsx_jammersSet.length) {
          const c = this.dsx_jammersSet.find(item => (item.id = r.cannon_id))

          if (c) {
            const dsx = this.dsxSensorById(c.rf_sensor_id)

            if (dsx) {
              cannon.name = dsx.name
              cannon.serial_number = dsx.serial_number
            }
          }
        }

        let duration = '-'
        if (r.deactivated_at !== null) {
          const activated = new Date(r.activated_at).getTime()
          const deactivated = new Date(r.deactivated_at).getTime()

          duration = (Math.abs(activated - deactivated) / 1000).toString()
        }

        return {
          ...r,
          cannon: cannon.name,
          cannon_serial: cannon.serial_number,
          activation: r.activated_manually ? 'manual' : 'auto',
          deactivation: r.deactivated_manually ? 'manual' : 'auto',
          date: this.$_getSiteDate(r.activated_at),
          started_at: this.$_getSiteTime(r.activated_at),
          stopped_at:
            r.deactivated_at === null
              ? '-'
              : this.$_getSiteTime(r.deactivated_at),
          duration: duration
        }
      })
    },
    isDisable() {
      return this.events === null || this.status === 'LOADING'
    },
    recordingTargetStatsIds() {
      return Object.values(this.recordingsSet as any[]).map(
        v => v.radar_target_stats_id
      )
    }
  },
  methods: {
    ...mapActions('reports', ['fetchAnalyticsData', 'downloadAnalyticsData']),
    ...mapActions('cannons', { fetchDsxJammers: 'FETCH_DSX_JAMMERS' }),
    async loadDsxJammers() {
      this.dsx_jammersSet = await this.fetchDsxJammers({
        site_id: this.activeSiteId
      })
    },
    async downloadReport() {
      const params = this.getRequestParams(false)
      const downloadedData = await this.downloadAnalyticsData({
        format: 'csv',
        params: params,
        timezone: this.timezone
      })
      downloadedData.click()
    },
    getRequestParams(paginated = true) {
      let params = {
        start_time: this.range.start_time,
        end_time: this.range.end_time,
        type: this.reportType,
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage,
        site_id: this.activeSiteId
      }
      if (!paginated) {
        delete params.page
        delete params.per_page
      }
      return params
    },
    async getTableData() {
      clearTimeout(this.debounceTimer)

      this.debounceTimer = setTimeout(async () => {
        this.fetchingData = true
        const params = {
          start_time: this.range.start_time,
          end_time: this.range.end_time,
          type: this.reportType,
          page: this.pagination.page,
          per_page: this.pagination.rowsPerPage,
          site_id: this.activeSiteId,
          ['sort_by_' + this.pagination.sortBy]: this.pagination.descending
            ? 'DESC'
            : 'ASC'
        }
        this.events = await this.fetchAnalyticsData({
          format: 'json',
          params: params
        })
        this.pagination.totalItems = await this.fetchAnalyticsData({
          format: 'json',
          params: { total_count: true, ...params }
        })
        this.fetchingData = false
      }, 500)
    },
    getSiteTime(time) {
      return moment.tz(time, this.timezone).format('YYYY-MM-DD HH:mm:ss z')
    },
    getUserName(userId) {
      if (!userId) return

      const user = Object.values(this.usersList as any[]).find(
        u => u.id === userId
      )
      if (!user) return `Deleted user (ID: ${userId})`

      return user.name
    },
    onClickClose() {
      this.dialog = false
    }
  },
  watch: {
    activeSiteId: {
      handler: async function() {
        this.events = []
        await this.getTableData()
      }
    },
    pagination: {
      handler: async function() {
        await this.getTableData()
      }
    }
  }
})
