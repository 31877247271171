<template>
  <div>
    <v-dialog
      :value="!!zmask"
      persistent
      scrollable
      content-class="zmask-dialog"
    >
      <v-card>
        <v-card-text>
          <v-flex xs12>
            <v-layout row wrap fill-height v-if="zmask">
              <v-flex
                :class="formClass"
                class="sensorForm"
                data-testid="form-group"
              >
                <v-card>
                  <v-card-title>
                    <span class="title primary--text">{{ formTitle }}</span>
                    <v-spacer />
                    <!--                hiding this options since the timeframe functionality is not working-->
                    <v-select
                      :items="maskOptions"
                      label="Mask Options"
                      v-model="maskType"
                      v-show="false"
                      color="primary"
                    />
                  </v-card-title>
                  <v-card-text>
                    <v-alert :value="error" outline color="primary">
                      {{ error }}
                    </v-alert>
                    <radar-z-mask-form
                      :disabled="isAddEditDisabled"
                      :mask="zmask"
                      v-show="maskType === 'manual'"
                    />
                    <timeframe
                      class="elevation-0"
                      title="Zone mask from"
                      :timezone="timezone"
                      @change="updateTime"
                      v-show="maskType === 'time'"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-layout row pb-5>
                      <v-flex xs12 shrink pl-2>
                        <d-submit
                          :disable="isAddEditDisabled"
                          includeCancel
                          @onCancel="cancelCreation"
                          @onSubmit="saveMask"
                          v-bind="{ status, error }"
                        />
                      </v-flex>
                    </v-layout>
                  </v-card-actions>
                </v-card>
              </v-flex>
              <v-flex
                :class="mapClass"
                class="pl-1 pb-3 align-self-center"
                data-testid="map-group"
              >
                <v-card
                  flat
                  class="sensorForm"
                  v-show="maskType === 'manual'"
                  height="80vh"
                >
                  <v-card-text class="sensorForm">
                    <RadarDetector @update="updateRadarDetections" />
                    <mask-map
                      type="radar"
                      :sensor="sensor"
                      :sentry="sentry"
                      :mask="zmask"
                      :detections="radarDetections"
                      :otherMasks="activeOtherMasks"
                      @disableNewFusionDetections="disableNewFusionDetections"
                    />
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-layout wrap fill-height v-resize="onResize">
      <v-toolbar class="transparent elevation-0">
        <v-btn
          flat
          color="primary"
          icon
          data-cy="create-zmask"
          @click="createZMask"
          :disabled="isAddButtonDisabled"
        >
          <v-icon>add</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <span :class="limit ? 'grey--text' : 'error--text'">
          {{ `${masksNb}/32 Zone Masks` }}
        </span>
      </v-toolbar>
      <v-flex xs12>
        <div v-if="!isSmallScreen">
          <v-data-table
            data-testid="radar-mask-list"
            data-testid-type="table"
            :headers="[
              ...zmaskHeaders,
              { text: 'Active', width: '1%' },
              { text: 'Actions', width: '1%' }
            ]"
            :items="maskForCurrentSensor"
            dense
            :pagination.sync="pagination"
            :rows-per-page-items="[10, 25]"
            class="elevation-0"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td v-for="({ value }, i) in zmaskHeaders" :key="`mask-${i}`">
                  {{ props.item[value] }}
                </td>
                <td>
                  <v-switch
                    primary
                    hide-details
                    color="primary"
                    @change="toggleMask(props.item)"
                    :input-value="props.item.active"
                  />
                </td>
                <td>
                  <v-btn
                    icon
                    small
                    @click="clickDownload(props.item)"
                    data-cy="download-mask"
                  >
                    <v-icon small>save</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    small
                    @click="clickEdit(props.item)"
                    data-cy="edit-mask"
                    v-show="deleteMaskId !== props.item.id"
                    :disabled="isAddEditDisabled"
                  >
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    icon
                    v-show="deleteMaskId !== props.item.id"
                    @click="clickDelete(props.item)"
                  >
                    <v-icon small>delete</v-icon>
                  </v-btn>
                  <v-btn
                    v-show="deleteMaskId === props.item.id"
                    small
                    icon
                    flat
                    color="primary"
                    @click="deleteMask(props.item)"
                    :disabled="status === 'LOADING'"
                    :loading="status === 'LOADING'"
                  >
                    <v-icon small>done</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    flat
                    small
                    v-show="deleteMaskId === props.item.id"
                    @click="deleteMaskId = null"
                    color="primary"
                  >
                    <v-icon small>close</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <div v-else>
          <div
            v-for="mask in radarMaskZonesSet[this.sensor.id]"
            :key="mask.id"
            data-testid="radar-mask-list"
            data-testid-type="list"
          >
            <RadarMaskItem
              :mask="mask"
              :columns="itemColumns"
              @onEdit="clickEdit($event)"
              @onDelete="deleteMask($event)"
              @onToggle="toggleMask($event)"
              @onDownload="clickDownload($event)"
            />
            <v-divider />
          </div>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import RadarZMaskForm from '@/components/Forms/Sensors/RadarZMaskForm'
import MaskMap from '@/components/Map/MaskMap'
import { Timeframe } from '@/components/EventReport'
import { mapActions, mapGetters, mapState } from 'vuex'
import RadarDetector from '@/components/Map/Detection/RadarDetector'
import RadarMaskItem from '@/components/Sentry/SensorGroups/Sensors/RadarMasks/RadarMaskItem.vue'
import {
  convertValIfImperial,
  convertUnitIfImperial
} from '@/store/utils/index'
import { ESiteMode } from '@/store/modules/sites/types'
import {
  MAX_RANGE,
  MAX_RCS,
  MIN_RANGE,
  MIN_RCS
} from '@/store/modules/radar_mask_zones/types'

const props = {
  sensor: {
    type: Object,
    default: () => ({
      zmask: []
    })
  },
  sentry: {
    type: Object,
    default: () => ({})
  },
  dialog: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'ZMask',
  components: {
    RadarMaskItem,
    RadarDetector,
    RadarZMaskForm,
    Timeframe,
    MaskMap
  },
  props,
  data: () => ({
    windowWidth: 0,
    radarDetections: {},
    deleteMaskId: null,
    editmode: false,
    maskType: 'manual',
    maskForCurrentSensor: [],
    maskOptions: [
      { text: 'Manual', value: 'manual' },
      { text: 'Timeframe', value: 'time' }
    ],
    zmask: null,
    zmaskHeaders: [
      {
        text: 'Id',
        value: 'id',
        align: 'left',
        width: '1%'
      },
      {
        text: 'Name',
        value: 'name',
        align: 'left',
        width: '1%'
      },
      {
        text: 'Azimuth (°)',
        value: 'azimuth',
        width: '1%'
      },
      {
        text: 'Elevation (°)',
        value: 'elevation',
        width: '1%'
      },
      {
        text: 'Range (m)',
        value: 'range',
        width: '1%'
      },
      {
        text: 'RCS (dBsm)',
        value: 'rcs',
        width: '1%'
      },
      {
        text: 'Hard Mask',
        value: 'hard_mask',
        width: '1%'
      }
    ],
    pagination: {
      rowsPerPage: 10
    }
  }),
  mounted() {
    this.windowWidth = window.innerWidth
    this.zmaskHeaders[4].text = `Range (${this.convertUnitIfImperial(
      this.systemSetting,
      'm'
    )})`
    this.setMaskForCurrentSensor()
  },
  computed: {
    ...mapState('radar_mask_zones', ['status', 'error', 'radarMaskZonesSet']),
    ...mapGetters('sites', ['activeSite', 'activeSiteId']),
    ...mapGetters('system', ['systemSetting']),
    isAddEditDisabled() {
      return this.activeSite.mode !== ESiteMode.Calibration
    },
    timezone() {
      return this.activeSite && this.activeSite.timezone
    },
    limit() {
      return this.maskForCurrentSensor?.length < 32
    },
    isAddButtonDisabled() {
      return !this.limit || this.activeSite.mode !== 'calibration'
    },
    isNewMask() {
      return this.zmask && !this.zmask.id
    },
    formTitle() {
      return this.isNewMask
        ? `New Zone Mask`
        : `Edit Zone Mask #${this.zmask.id}`
    },
    itemColumns() {
      let cols = 2
      if (this.windowWidth > 550 && this.windowWidth <= 800) {
        cols = 3
      } else if (this.windowWidth > 800) {
        cols = 4
      }
      return cols
    },
    isSmallScreen() {
      return this.windowWidth < 1160
    },
    verticalEditDialog() {
      return this.windowWidth < 1470
    },
    formClass() {
      return this.verticalEditDialog ? 'xs12 order-xs2' : 'xs5'
    },
    mapClass() {
      return this.verticalEditDialog ? 'xs12 order-xs1' : 'xs7'
    },
    masksNb() {
      return this.maskForCurrentSensor?.length || 0
    },
    activeOtherMasks() {
      if (this.sensor.id == null) return []
      const masks = this.radarMaskZonesSet[this.sensor.id]
      return (
        masks &&
        masks.filter(mask => mask.active === true && mask.id !== this.zmask?.id)
      )
    }
  },
  methods: {
    ...mapActions('radar_mask_zones', [
      'setMaskEditingState',
      'CREATE_RADAR_MASK_ZONE',
      'UPDATE_RADAR_MASK_ZONE',
      'DELETE_RADAR_MASK_ZONE'
    ]),
    ...mapActions('detection', {
      disableNewFusionDetections: 'disableNewFusionDetections'
    }),
    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,

    onResize() {
      this.windowWidth = window.innerWidth
    },
    updateRadarDetections(detections) {
      this.radarDetections = detections
    },
    clickEdit(mask) {
      this.setMaskEditingState(true)
      this.maskType = 'manual'
      this.zmask = {
        id: mask['id'],
        name: mask['name'],
        azimuth: [mask['azimuth_min'], mask['azimuth_max']],
        elevation: [mask['elevation_min'], mask['elevation_max']],
        range: [mask['range_min'], mask['range_max']],
        rcs: [mask['rcs_min'], mask['rcs_max']],
        hard_mask: mask['hard_mask']
      }
    },
    updateTime({ start, end }) {
      Object.assign(this.zmask, {
        start_time: start.utc().format('YYYY-MM-DD HH:mm:ss'),
        end_time: end.utc().format('YYYY-MM-DD HH:mm:ss')
      })
    },
    clickDelete(mask) {
      this.deleteMaskId = mask.id
    },
    async toggleMask(mask) {
      // this is required since the mask object coming from the event is outdated
      let updatedMask = this.radarMaskZonesSet[this.sensor.id]?.find(
        m => m.id === mask.id
      )

      updatedMask.active = !updatedMask.active

      await this.UPDATE_RADAR_MASK_ZONE({
        siteID: this.activeSiteId,
        mask: updatedMask
      })
    },
    async deleteMask(mask) {
      await this.DELETE_RADAR_MASK_ZONE({siteID: this.activeSiteId, mask: mask})
      this.setMaskForCurrentSensor()
    },
    clickDownload(mask) {
      const hiddenElement = document.createElement('a')
      hiddenElement.href = 'data:attachment/text,' + encodeURI(mask.kml)
      hiddenElement.target = '_blank'
      hiddenElement.download = `radar_${this.sensor.id}_mask_${mask.id}.kml`
      hiddenElement.click()
    },
    createZMask() {
      this.setMaskEditingState(true)
      this.zmask = {
        id: null,
        azimuth: [0, 0],
        elevation: [0, 0],
        range: [MIN_RANGE, MAX_RANGE],
        rcs: [MIN_RCS, MAX_RCS],
        hard_mask: false,
        name: ''
      }
    },
    cancelCreation() {
      this.zmask = null
      this.setMaskEditingState(false)
    },
    setMaskForCurrentSensor() {
      let sensorsMasks = this.radarMaskZonesSet[this.sensor.id] || []

      this.maskForCurrentSensor = [...sensorsMasks].map(
        mask => {
          const merged = {
            azimuth: `${mask.azimuth_min} / ${mask.azimuth_max}`,
            elevation: `${mask.elevation_min} / ${mask.elevation_max}`,
            range: `${this.convertValIfImperial(
              this.systemSetting,
              mask.range_min,
              'm'
            )} / ${this.convertValIfImperial(
              this.systemSetting,
              mask.range_max,
              'm'
            )}`,
            rcs: `${mask.rcs_min} / ${mask.rcs_max}`
          }
          return { ...mask, ...merged }
        }
      )
    },
    async saveMask() {
      const { sensor: radar, zmask } = this
      const { start_time, end_time, azimuth, elevation, rcs, hard_mask } = zmask

      let { range } = zmask

      // if imperial, save as metric
      range[0] = this.convertValIfImperial(
        this.systemSetting,
        range[0],
        'ft',
        1
      )
      range[1] = this.convertValIfImperial(
        this.systemSetting,
        range[1],
        'ft',
        1
      )

      const mask = { id: zmask['id'], name: zmask['name'] }

      if (start_time && end_time) {
        Object.assign(mask, { start_time, end_time })
      } else {
        Object.assign(mask, {
          radar_id: this.sensor.id,
          azimuth_min: azimuth[0],
          azimuth_max: azimuth[1],
          elevation_min: elevation[0],
          elevation_max: elevation[1],
          range_min: range[0],
          range_max: range[1],
          rcs_min: rcs[0],
          rcs_max: rcs[1],
          hard_mask
        })
      }
      if (mask.id) {
        await this.UPDATE_RADAR_MASK_ZONE({
          siteID: this.activeSiteId,
          mask: mask
        })
      } else {
        await this.CREATE_RADAR_MASK_ZONE({
          siteID: this.activeSiteId,
          mask: mask
        })
      }
      if (this.status === 'OK') {
        setTimeout(() => {
          this.zmask = null
        }, 500)
      }
      await this.setMaskEditingState(false)
      this.setMaskForCurrentSensor()
    }
  },
  watch: {
    maskType() {
      window.dispatchEvent(new Event('resize'))
    },
    zmask() {
      this.$store.commit('radar_mask_zones/SET_ERROR', null)
      this.$store.commit('radar_mask_zones/SET_STATUS', null)
    }
  }
}
</script>
<style scoped>
>>> .zmask-dialog {
  top: 2rem;
  left: 2.5rem;
  width: calc(100vw - 5rem);
  height: calc(100% - 6rem);
  margin: 0;
  max-height: unset;
}
</style>
