















































































import Vue from 'vue'
//@ts-ignore
import { frequency, length, speed } from 'units-converter'
import DetailSection from '@/components/Notifications/SensorFusion/DetailSection.vue'
import { IFusionTrackData } from '@/components/Map/EventMaps/types'
import { IDetailSectionItem } from '@/components/Notifications/SensorFusion/types'
import {
  computeDirection,
  humanReadableTargetID,
  latLngToMgrs,
  validBeaconLocation,
  validBeaconSerialNumber
} from '@/utils/utils'
import { mapGetters, mapState } from 'vuex'
import capitalize from 'lodash/capitalize'
import { isNullOrUndefined } from '@/utils/utils'
import moment from 'moment'

const props = {
  track: {
    type: Object as () => IFusionTrackData,
    default: null
  },
  event: {
    type: Object as () => {},
    default: null
  },
  sensors: {
    type: Object as () => {},
    default: {}
  },
  showLocation: {
    type: Boolean,
    default: false
  },
  hasRfSensor: {
    type: Boolean,
    default: false
  },
  hasRadarSensor: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'EventData',
  props,
  components: {
    DetailSection
  },
  data() {
    return {
      lastLat: null,
      lastLng: null,
      detailValueTextSize: 'title',
      previousDirection: {
        direction: null,
        cardinal: null
      },
      detailBackgroundColor: '#303030A6'
    }
  },
  computed: {
    ...mapGetters('detection_labels', ['formattedLabel']),
    ...mapGetters('system', ['systemSetting']),
    ...mapState(['mgrsEnabled']),
    displayUnit(): string {
      return this.systemSetting.displayUnit
    },
    vendor(): string {
      return this.track?.vendor || null
    },
    protocol(): string {
      return this.track?.protocol || null
    },
    frequency(): string {
      if (!this.track?.frequency) return null
      const freq = frequency(this.track.frequency)
        .from('Hz')
        .toBest()
      return `${freq.value.toFixed(2)} ${freq.unit}`
    },
    mgrsData(): string {
      if (this.track?.latitude == null || this.track?.longitude == null)
        return null
      return latLngToMgrs(this.track?.latitude, this.track?.longitude, true)
    },
    altitude(): string {
      let altitude
      if (!this.track?.altitude) return null
      if (this.displayUnit === 'metric') {
        altitude = length(this.track.altitude)
          .from('m')
          .toBest({ exclude: ['mm', 'cm'] })
      } else {
        altitude = this.convertLengthToImperial(this.track.altitude, 'm', 'ft')
      }
      return `${altitude.value.toFixed(2)} ${altitude.unit}`
    },
    site_distance(): string {
      let dist
      if (!this.track?.site_distance) return null
      if (this.displayUnit === 'metric') {
        dist = length(this.track.site_distance)
          .from('m')
          .toBest({ exclude: ['mm', 'cm'] })
      } else {
        dist = this.convertLengthToImperial(this.track.site_distance, 'm', 'ft')
      }
      return `${dist.value.toFixed(2)} ${dist.unit}`
    },
    speed(): string {
      let sp
      if (!this.track?.speed) return null
      if (this.displayUnit === 'metric') {
        sp = speed(this.track.speed)
          .from('m/s')
          .toBest()
      } else {
        sp = speed(this.track.speed)
          .from('m/s')
          .to('ft/s')
        sp = speed(sp.value)
          .from(sp.unit)
          .toBest({ exclude: ['knot'] })
      }
      return `${sp.value.toFixed(2)} ${sp.unit}`
    },
    latitude(): string {
      if (!this.track?.latitude) return null
      return this.track.latitude.toFixed(5)
    },
    longitude(): string {
      if (!this.track?.longitude) return null
      return this.track.longitude.toFixed(5)
    },
    cardinalDirection(): string {
      return this.getCardinal(this.direction) || null
    },
    direction(): number {
      let value = undefined
      try {
        value = computeDirection(
          { lat: this.lastLat, lng: this.lastLng },
          { lat: this.track?.latitude, lng: this.track?.longitude }
        )
      } catch {
        value = null
      }
      return value
    },
    classification(): string {
      return this.formattedLabel(capitalize(this.track?.classification || '-'))
    },
    time_on_target(): string {
      if (
        typeof this.track?.time_on_target === 'number' &&
        this.track?.time_on_target > 0
      )
        return moment.utc(1000 * this.track.time_on_target).format('m[m] ss[s]')
      else return null
    },
    classificationSectionDetails(): IDetailSectionItem[] {
      return [
        { label: 'Class', value: this.classification || '-' },
        {
          label: 'Group ID',
          value: humanReadableTargetID(this.event.target_id) || '-'
        }
      ]
    },
    rfSectionDetails(): IDetailSectionItem[] {
      return [
        { label: 'Protocol', value: this.protocol || '-' },
        { label: 'Frequency', value: this.frequency || '-' },
        { label: 'Vendor', value: this.vendor || '-' },
        { label: 'SSID', value: this.ssid || '-' }
      ]
    },
    locationSectionDetails(): IDetailSectionItem[] {
      const details = []
      if (this.mgrsEnabled) {
        let mgrs = '-'
        if (this.latitude && this.longitude) {
          mgrs = latLngToMgrs(this.latitude, this.longitude)
        }
        details.push({ label: 'MGRS', value: mgrs, fullWidth: true })
      } else {
        details.push(
          { label: 'Latitude', value: this.latitude || '-' },
          { label: 'Longitude', value: this.longitude || '-' }
        )
      }
      details.push(
        { label: 'Height', value: this.altitude || '-' },
        { label: 'Site Distance', value: this.site_distance || '-' },
        {
          label: 'Direction',
          value: isNullOrUndefined(this.direction)
            ? '-'
            : this.cardinalDirection
        },
        { label: 'Speed', value: this.speed || '-' },
        { label: 'Time on Target', value: this.time_on_target || '-' }
      )
      if (this.hasRadarSensor)
        details.push({
          label: 'RCS',
          value: this.track.rcs ? this.track.rcs.toFixed(2) : '-'
        })
      return details
    },
    gcsLatitude(): number | null {
      return validBeaconLocation(this.track.gcs_latitude)
    },
    gcsLongitude(): number | null {
      return validBeaconLocation(this.track.gcs_longitude)
    },
    homeLatitude(): number | null {
      return validBeaconLocation(this.track.home_latitude)
    },
    homeLongitude(): number | null {
      return validBeaconLocation(this.track.home_longitude)
    },
    droneSerialNumber(): string | null {
      return validBeaconSerialNumber(this.track.drone_serial_number)
    },
    rfBeaconSectionDetails(): IDetailSectionItem[] {
      const details = []
      if (this.mgrsEnabled) {
        let mgrs: string = '-'
        if (this.gcsLatitude && this.gcsLongitude) {
          mgrs = latLngToMgrs(this.gcsLatitude, this.gcsLongitude)
        }
        details.push({ label: 'GCS MGRS', value: mgrs, fullWidth: true })
        mgrs = '-'
        if (this.homeLatitude && this.homeLongitude) {
          mgrs = latLngToMgrs(this.homeLatitude, this.homeLongitude)
        }
        details.push({ label: 'Home MGRS', value: mgrs, fullWidth: true })
      } else {
        details.push(
          {
            label: 'GCS Latitude',
            value: this.gcsLatitude?.toFixed(5) || '-'
          },
          {
            label: 'GCS Longitude',
            value: this.gcsLongitude?.toFixed(5) || '-'
          },
          {
            label: 'Home Latitude',
            value: this.homeLatitude?.toFixed(5) || '-'
          },
          {
            label: 'Home Longitude',
            value: this.homeLongitude?.toFixed(5) || '-'
          }
        )
      }
      details.push({
        label: 'Drone Serial Number',
        value: this.droneSerialNumber || '-'
      })
      return details
    }
  },
  methods: {
    convertLengthToImperial(val: number, from: string, to: string) {
      let l
      l = length(val)
        .from(from)
        .to(to)
      l = length(l.value)
        .from(l.unit)
        .toBest({ exclude: ['ft-us', 'fathom', 'nMi'] })
      return l
    },
    getCardinal(angle) {
      const degree = 360 / 8
      angle = angle + degree / 2

      if (angle >= 0 && angle < degree) return 'N'
      if (angle >= degree && angle < 2 * degree) return 'NE'
      if (angle >= 2 * degree && angle < 3 * degree) return 'E'
      if (angle >= 3 * degree && angle < 4 * degree) return 'SE'
      if (angle >= 4 * degree && angle < 5 * degree) return 'S'
      if (angle >= 5 * degree && angle < 6 * degree) return 'SW'
      if (angle >= 6 * degree && angle < 7 * degree) return 'W'
      if (angle >= 7 * degree && angle < 8 * degree) return 'NW'
      //Should never happen:
      return 'N'
    }
  },
  watch: {
    direction: {
      handler: function(val) {
        if (val != null) {
          this.previousDirection = {
            direction: val,
            cardinal: this.getCardinal(this.direction)
          }
        }
      }
    },
    track: {
      handler: function(newVal: IFusionTrackData, oldVal: IFusionTrackData) {
        this.lastLat = oldVal?.latitude || null
        this.lastLng = oldVal?.longitude || null
      },
      immediate: true
    }
  }
})
