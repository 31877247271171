<template>
  <base-map
    :center="center"
    :zoom="zoom"
    @click="onMapClick"
    defaultMode="calibration"
  >
    <template v-if="showAllMasks">
      <LPolygon
        v-for="amask in additionalMasks"
        :key="`mask-${amask.name}-${amask.id}`"
        color="#888"
        fill-color="#888"
        :fill-opacity="0.5"
        :weight="1"
        :lat-lngs="getRange(amask)"
        @ready="processMasks"
      />
    </template>
    <LPolygon
      color="#000"
      :weight="1"
      fill-color="#000"
      :fill-opacity="0.6"
      :lat-lngs="range"
    />
    <radar-sector-coverage :sensor="sensor" />
    <template v-if="fusionDetectionItems.length > 0">
      <FusionDetection
        v-for="detection in fusionDetectionItems"
        :detection="detection"
        class="fusion-detection"
        :key="`fusion-detection_${detection.target_id}`"
        :hide-location-variance="true"
      />
    </template>
    <l-marker :icon="sentryIcon" :lat-lng="center" />
    <template v-slot:additional-controls>
      <l-control position="bottomleft">
        <FusionDetectionPopup
          v-if="fusionSelectedItem"
          :elevation="fusionSelectedItem.elevation"
          :rcs="fusionSelectedItem.rcs"
          :groupId="fusionSelectedItem.groupId"
        />
      </l-control>
      <l-control position="topright" v-if="otherMasks.length > 0">
        <v-speed-dial
          open-on-hover
          direction="left"
          transition="slide-x-reverse-transition"
        >
          <v-btn
            slot="activator"
            @click.stop="showAllMasks = !showAllMasks"
            fab
            small
            color="#333"
          >
            <v-icon>
              {{ showAllMasks ? 'blur_on' : 'blur_off' }}
            </v-icon>
          </v-btn>
          <div class="maskLayerLabel">
            {{ showAllMasks ? 'Showing other masks' : 'Hiding other masks' }}
          </div>
        </v-speed-dial>
      </l-control>
    </template>
  </base-map>
</template>

<script>
import utils from './utils'
import L from 'leaflet'
import BaseMap from './BaseMap'
import { LMarker, LControl, LPolygon } from 'vue2-leaflet'

import { mapActions, mapState } from 'vuex'

import { RadarSectorCoverage } from '@/components/Map/Coverage'
import FusionDetection from '@/components/Map/Detection/SensorFusion/FusionDetection'
import FusionDetectionPopup from '@/components/Map/Detection/SensorFusion/FusionDetectionPopup'
import { humanReadableTargetID } from '@/utils/utils'

const props = {
  zoom: {
    type: Number,
    default: 15
  },
  mask: {
    type: Object,
    default: () => ({})
  },
  sensor: {
    type: Object,
    default: () => ({
      latitude: 0,
      longitude: 0
    })
  },
  sentry: {
    type: Object,
    default: () => ({})
  },
  type: {
    type: String,
    default: 'radar'
  },
  hasCompass: {
    type: Boolean,
    default: false
  },
  otherMasks: {
    type: Array,
    default: () => []
  }
}

export default {
  name: 'MaskMap',
  components: {
    RadarSectorCoverage,
    BaseMap,
    LMarker,
    LControl,
    LPolygon,
    FusionDetection,
    FusionDetectionPopup
  },
  props,
  data: () => ({
    clickedBeam: false,
    detached: false,
    lastDetections: [],
    selected: null,
    expanded: false,
    showAllMasks: true
  }),
  mounted() {
    // opt-in to new fusion detections
    this.enableNewFusionDetections()
  },
  beforeDestroy() {
    this.$emit('disableNewFusionDetections')
  },
  computed: {
    ...mapState('sensors', ['activeKey']),
    ...mapState('detection', ['fusionDetections', 'selectedDetections']),
    fusionDetectionItems() {
      return Object.values(this.fusionDetections)
    },
    fusionSelectedItem() {
      if (this.selectedDetections[0]) {
        return {
          elevation: this.fusionDetections[this.selectedDetections[0]].detection_contributions[0].elevation,
          rcs: this.fusionDetections[this.selectedDetections[0]].rcs,
          groupId: humanReadableTargetID(this.selectedDetections[0])
        }
      }
      else return null
    },
    additionalMasks() {
      return this.otherMasks.map(mask => {
        return {
          id: mask['id'],
          name: mask['name'],
          azimuth: [mask['azimuth_min'], mask['azimuth_max']],
          elevation: [mask['elevation_min'], mask['elevation_max']],
          range: [mask['range_min'], mask['range_max']],
          rcs: [mask['rcs_min'], mask['rcs_max']],
          hard_mask: mask['hard_mask']
        }
      })
    },
    center() {
      return [this.sensor.latitude || 0, this.sensor.longitude || 0]
    },
    range() {
      const { direction = 0 } = this.sensor || {}
      return utils.createRadarMaskRange({
        latitude: this.center[0],
        longitude: this.center[1],
        range: this.mask.range,
        direction,
        azimuth: this.mask.azimuth
      })
    },
    sentryIcon() {
      return L.icon({
        iconUrl: require(`@/assets/sentry/green.png`),
        iconSize: [40, 40],
        iconAnchor: [20, 40]
      })
    }
  },
  methods: {
    ...mapActions('sensors', ['selectDetection']),
    ...mapActions('detection', {
      enableNewFusionDetections: 'enableNewFusionDetections'
    }),
    getRange(mask) {
      const { direction = 0 } = this.sensor || {}
      return utils.createRadarMaskRange({
        latitude: this.center[0],
        longitude: this.center[1],
        range: mask.range,
        direction,
        azimuth: mask.azimuth
      })
    },
    processMasks(mask) {
      mask.bringToBack()
    },
    onMapClick(e) {
      this.selectDetection(null)
    },
    detectionsUpdated(detections) {
      this.detections = detections
    }
  }
}
</script>

<style>
.detection-overlay {
  position: absolute;
  top: 1rem;
  left: 60px;
  z-index: 9999;
  background-color: red;
}

.maskLayerLabel {
  position: relative;
  z-index: -1;
  left: 30px;
  width: 130px;
  color: #aaa;
  text-align: center;
  cursor: default;
  padding: 3px 25px 3px 10px;
  background-color: #333;
  border-radius: 10px;
  line-height: 130%;
}
</style>
