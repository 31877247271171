
























































































import Vue, { PropType } from 'vue'
import { FormMixin } from '@/components/Mixins'
import { mapGetters, mapActions, mapState } from 'vuex'
import AGLSlider from './AGLSlider.vue'
import AGLGraphic from './AGLGraphic.vue'
import {
  IAGLMask,
  IAGLMaskItem,
  IAGLRange
} from '@/store/modules/agl_mask/types'
import {
  convertValIfImperial,
  convertUnitIfImperial
} from '@/store/utils/index'

const props = {
  sensor: {
    type: Object,
    default: () => ({})
  }
}

const defaultMask = {
  min: 0,
  max: 30,
  enabled: false
}

export default Vue.extend({
  name: 'AGLMask',
  mixins: [FormMixin],
  props,
  data: () => ({
    previewLoading: true,
    error: false,
    apiError: '',
    stageDimensions: {
      width: 1000,
      height: 500
    },
    window: {
      width: 0,
      height: 0
    },
    mask: {
      min: 0,
      max: 30,
      enabled: false
    },
    aglGraphicContainerWidth: 0,
    aglGraphicContainerHeight: 0,
    valid: false,
    activeArea: {
      percentage: [0, 0],
      value: [0, 0]
    },
    labels: {},
    sliderValid: true,
    apiCallInProgress: false,
    buttonText: 'save',
    buttonColor: 'primary'
  }),
  components: {
    AGLGraphic,
    AGLSlider
    // DRangeSlider,
    // AGLMaskGui
  },
  mounted(): void {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.initButton()
  },
  beforeDestroy(): void {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    ...mapGetters('radars', ['radarsSet', 'status']),
    ...mapGetters('agl_mask', ['aglMasks']),
    ...mapState('sites', ['activeSiteId']),
    ...mapGetters('system', ['systemSetting']),
    range: {
      get(): IAGLMask {
        const { min, max } = this.mask
        return [min, max]
      },
      set([min, max]) {
        Object.assign(this.mask, { min, max })
      }
    },
    buttonDisabled(): boolean {
      return (
        this.range[0] < this.sliderMinLimit ||
        this.range[1] > this.sliderMaxLimit ||
        !this.sliderValid
      )
    },
    sliderMaxLimit(): number {
      return this.convertValIfImperial(this.systemSetting, 3000, 'm')
    },
    sliderMinLimit(): number {
      return this.convertValIfImperial(this.systemSetting, -3000, 'm')
    },
    normalisedAglMasks() {
      const masks = {}
      Object.keys(this.aglMasks).forEach(key => {
        const mask = Object.assign({}, this.aglMasks[key])
        mask.min = this.convertValIfImperial(
          this.systemSetting,
          mask.min,
          'm',
          2
        )
        mask.max = this.convertValIfImperial(
          this.systemSetting,
          mask.max,
          'm',
          2
        )
        masks[key] = mask
      })
      return masks
    }
  },
  methods: {
    ...mapActions('agl_mask', ['updateAGLMask', 'fetchAGLMaskTotal']),

    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,

    updatePreviewLoading(val) {
      this.previewLoading = val
    },
    initButton(): void {
      this.buttonText = 'save'
      this.buttonColor = 'primary'
    },
    onSliderValidate(val): void {
      this.sliderValid = val
    },
    setLabels(labels: IAGLRange): void {
      const values = {}
      this.labels = Object.keys(labels).forEach(label => {
        let key = label
        if (key === 'min') {
          key = '0%'
        }
        if (key === 'max') {
          key = '100%'
        }
        key = key.replace('%', '')
        key = String(100 - parseInt(key))
        values[key] = labels[label][0]
      })
      this.$set(this, 'labels', values)
    },
    handlePositionUpdated(position): void {
      // position will be in the currently selected unit
      this.activeArea = position
      this.range = this.activeArea.value
    },
    onResize(): void {
      this.$set(this.window, 'height', window.innerHeight)
      this.$set(this.window, 'width', window.innerWidth)
    },
    async onSubmit(): Promise<void> {
      // if imperial, save as metric
      this.mask.min = this.convertValIfImperial(
        this.systemSetting,
        this.mask.min,
        'ft',
        2
      )
      this.mask.max = this.convertValIfImperial(
        this.systemSetting,
        this.mask.max,
        'ft',
        2
      )

      this.error = false
      this.apiError = null
      this.apiCallInProgress = true
      this.mask.radar_id = this.sensor.id
      const response = await this.updateAGLMask({
        siteID: this.activeSiteId,
        mask: this.mask
      })
      if (!response.success) {
        this.error = true
        this.apiError = response.data
      } else {
        await this.fetchAGLMaskTotal(this.activeSiteId)
      }
      if (!this.mask.id) {
        this.mask.id = response.maskId
      }

      setTimeout(() => {
        this.apiCallInProgress = false
        if (response.success) {
          this.buttonText = 'done'
          this.buttonColor = 'success'
        } else {
          this.buttonText = 'error'
          this.buttonColor = 'error'
        }
        setTimeout(() => {
          this.initButton()
          this.$emit('onClose')
        }, 500)
      }, 1000)
    }
  },
  watch: {
    'sensor.id': {
      immediate: true,
      handler(id): void {
        if (typeof id === 'number' && this.radarsSet[id]) {
          Object.assign(this.mask, this.normalisedAglMasks[id] || defaultMask)
        }
      }
    }
  }
})
