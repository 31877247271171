





























































import moment from 'moment-timezone'
import DeleteSensor from '@/components/Dialogs/DeleteSensor.vue'
import LabelTag from '@/components/EventReport/Camera/LabelTag.vue'
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import { IRecording } from '@/store/modules/recordings/types'
import bytes from 'bytes'

const props = {
  totalCount: {
    type: Number,
    default: 0
  }
}

export default Vue.extend({
  props,
  components: { LabelTag, DeleteSensor },
  data: () => ({
    debounceTimer: null,
    fetchingData: false,
    recordings: [] as IRecording[],
    pagination: {
      totalItems: 0,
      page: 1,
      rowsPerPage: 10
    },
    pageSizeItems: [5, 10, 25],
    reportType: 'camera_recordings',
    headers: [
      {
        text: 'Filename',
        value: 'filename',
        sortable: true
      },
      {
        text: 'Date',
        value: 'created_at',
        sortable: true
      },
      {
        text: 'Duration',
        value: 'duration',
        sortable: true
      },
      {
        text: 'Label',
        sortable: false,
        width: '250px'
      },
      {
        text: 'File Size',
        value: 'size',
        sortable: true
      },
      {
        text: 'Actions',
        sortable: false
      }
    ],
    dialog: false,
    activeRecording: null
  }),
  async mounted(): Promise<void> {
    await this.getDetectionLabels()
    this.$emitter.on('onChange:timerange', this.getTableDataForced)
    this.$emitter.on('updateTable', this.getTableDataForced)
  },
  beforeDestroy(): void {
    this.$emitter.off('onChange:timerange', this.getTableDataForced)
    this.$emitter.on('updateTable', this.getTableDataForced)
  },
  methods: {
    ...mapActions('reports', ['fetchAnalyticsData']),
    ...mapActions('detection_labels', ['getLabels', 'updateLabel']),
    async onLabelSelected(recordingId: number, data: string): Promise<void> {
      await this.updateLabel({
        label: data,
        id: recordingId
      })
      await this.getTableDataForced()
    },
    async getDetectionLabels(): Promise<void> {
      await this.getLabels()
    },
    async getTableDataForced(): Promise<void> {
      await this.getTableData(true)
    },
    async getTableData(invalidate: boolean = false): Promise<void> {
      clearTimeout(this.debounceTimer)

      this.debounceTimer = setTimeout(async () => {
        this.fetchingData = true
        const params = {
          start_time: this.range.start_time,
          end_time: this.range.end_time,
          type: this.reportType,
          page: this.pagination.page,
          per_page: this.pagination.rowsPerPage,
          site_id: this.activeSiteId,
          ['sort_by_' + this.pagination.sortBy]: this.pagination.descending ? 'DESC' : 'ASC'
        }
        this.recordings = await this.fetchAnalyticsData({
          invalidateCache: invalidate,
          format: 'json',
          params: params
        })
        this.pagination.totalItems = await this.fetchAnalyticsData({
          invalidateCache: invalidate,
          format: 'json',
          params: { total_count: true, ...params }
        })
        this.fetchingData = false
      }, 500)
    },
    getRecordingDate(date: string): string {
      return moment(date).fromNow()
    },
    getRecordingDuration(ms: number): string {
      if (!ms) return '-'
      const seconds = Math.round((ms / 1000) % 60)
      const minutes = Math.floor((ms / 1000 / 60) % 60)
      const hours = Math.floor(ms / 1000 / 60 / 60)
      return hours > 0 ? hours + 'h ' + minutes + 'm ' + seconds + 's'
        : minutes + 'm ' + seconds + 's'
    },
    getRecordingSize(size: number): string {
      return bytes(size)
    },
    async deleteRecording(recording: IRecording): Promise<void> {
      this.activeRecording = recording
      this.dialog = 'remove'
    },
    async onDelete(): Promise<void> {
      this.$emitter.emit('updateRecordingsReport')
      await this.getTableDataForced()
    },
    onClose(): void {
      this.activeRecording = null
      this.dialog = false
    },
    getDetectionLabel(detection: any): string {
      return detection.labels ?? null
    }
  },
  computed: {
    ...mapGetters('reports', [
      'status',
      'error',
      'range',
      'rfShowWhitelisted',
      'rfShowFiltered'
    ]),
    ...mapGetters('sites', ['activeSiteId']),
    ...mapGetters('detection_labels', ['labels']),
    detectionLabels(): any {
      const labels = {}
      this.recordings.forEach(recording => {
        labels[recording.id] = this.getDetectionLabel(recording)
      })
      return labels
    },
    noDataText(): string {
      return this.fetchingData ? 'Fetching data...' : 'No data available'
    }
  },
  watch: {
    activeSiteId: {
      handler: async function() {
        this.recordings = []
        await this.getTableDataForced()
      }
    },
    pagination: {
      handler: async function() {
        await this.getTableDataForced()
      }
    }
  }
})
